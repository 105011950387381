<template>
  <b-card>
    <h2>
      Zamówienie: {{ order.id }}
    </h2>
    Status: {{ order.status }}
    <hr>
    Data złożenia {{ order.created_at }}
    <hr>
    Data ostatniej edycji {{ order.updated_at }}
    <hr>
    email klienta: {{ order.user.email }}
    <hr>
    nazwa klienta: {{ order.user.name }}
    <hr>
    opis klienta: {{ order.user.description }}
  </b-card>
</template>

<script>
import axios from 'axios'
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      order: {},
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    axios.get(`${process.env.VUE_APP_API_URL}Order/${this.$route.params.id}`, config)
      .then(response => {
        this.order = response.data.order
      })
  },
  methods() {

  },
}
</script>

<style>

</style>
